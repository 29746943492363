import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './auth/auth.component';
import { MsalGuard } from '@azure/msal-angular';
import { AuthGuard } from './_guards/auth.guard';

const routes: Routes = [
 
  {
    path: '',
    loadChildren: './complaint/complaint.module#ComplaintModule',
   
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
