import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Client } from '@microsoft/microsoft-graph-client';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { BaseService } from './api-service';
import { OAuthSettings } from '../_settings/oauth';
import { User } from '../_interfaces/user';
import { Alerts, APIPaths, TokenHelper } from '../common';
import { Router } from '@angular/router';
import { AuditTrail } from '../interfaces';
import { AuditTrailService } from './audit-trail.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseService {
  public authenticated: boolean;
  public user: User;

  constructor(public httpClient: HttpClient, private auditService: AuditTrailService, private msalService: MsalService, private router: Router) {
    super(httpClient);
    this.authenticated = this.msalService.getUser() != null;
    this.getUser().then((user) => { this.user = user });
  }

  // Prompt the user to sign in and   // grant consent to the requested permission scopes
  async signIn(): Promise<any> {
    let result = await this.msalService.loginPopup(OAuthSettings.scopes).then(

    )
      .catch((reason) => {
        Alerts.showErrorMessage("Login Failed");
        
        return reason;
      });
    if (result)
      this.user = await this.getUser();
    /*
       */
    return result;
  }

  // Silently request an access token
  async getAccessToken(): Promise<string> {

    let result = await this.msalService.acquireTokenSilent(OAuthSettings.scopes)
      .catch((reason) => {
        if (localStorage.getItem('msal.error') === 'login_required') {
          this.router.navigate(['/'])
        }
        else {
          Alerts.showErrorMessage(JSON.stringify(reason, null, 2))
           
        }
      });

    return result;
  }

  private async getUser(): Promise<User> {
    if (!this.authenticated) return null;

    let graphClient = Client.init({
      // Initialize the Graph client with an auth
      // provider that requests the token from the
      // auth service
      authProvider: async (done) => {
        let token = await this.getAccessToken()
          .catch((reason) => {
            done(reason, null);
          });

        if (token) {
          done(null, token);
        } else {
          done("Could not get an access token", null);
        }
      }
    });

    // Get the user from Graph (GET /me)
    let graphUser = await graphClient.api('/me').get();
    
    let user = new User();
    user.displayName = graphUser.displayName;
    // Prefer the mail property, but fall back to userPrincipalName
    user.email = graphUser.mail || graphUser.userPrincipalName;

    return user;
  }

  login(result, audit) {
    let onSuccess = (value) => {
      let data = value.value;
      if (data.success) {
        if (result) {
          
          this.authenticated = true;
          let isAdmin = data.result.isAdmin == 1 ? true : false;
          localStorage.setItem('userStatus', String(isAdmin));
          return true;
        }
      }
      else {
        localStorage.clear();
         
        Alerts.showErrorMessage(data.message);
        return false;
      }
    };
    return this.service(this.post(APIPaths.login, audit)).pipe(
      map(value => this.processPayload(value)),
      map(onSuccess)
    );
  }
  // Sign out
  signOut(isMicrosoft = true) {
    let m = {
      id: TokenHelper.getCurrentUserName(),
      text: TokenHelper.getCurrentUserName()
    }
    let onSuccess = (value) => {
      let data = value.value;
      if (data.success) {
          this.msalService.logout();
          this.user = null;
          this.authenticated = false;
          localStorage.clear();
           
          return true;
      }
      else {
        Alerts.showErrorMessage(data.message);
        return false;
      }
    };
    return this.service(this.post(APIPaths.logout, m)).pipe(
      map(value => this.processPayload(value)),
      map(onSuccess)
    );

  }
}
