import { Injectable } from '@angular/core';
 import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BaseService } from './api-service';
import { DeviceTypeEnum, APIPaths } from '../common';
import { IpInfo, AuditTrail } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class AuditTrailService extends BaseService {

  constructor(  protected httpClient: HttpClient) {
    super(httpClient);
  }

 
}
