import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MsalModule } from '@azure/msal-angular';
import { ToastrModule } from 'ngx-toastr';

import { ErrorModule } from './error/error.module';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS, HttpClientXsrfModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthComponent } from './auth/auth.component';
import { OAuthSettings } from './_settings/oauth';
import { AuthHttpInterceptor } from './httpinterceptor/auth.http-interceptor';
import { AuthGuard } from './_guards/auth.guard';
 
@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpModule,
    HttpClientModule,
   
    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-CSRF-TOKEN'
    }),
    MsalModule.forRoot({
      clientID: OAuthSettings.appId,
      cacheLocation: "localStorage",
      popUp: true,
      // redirectUri: "https://portal.complaints.pgc.edu",
      // postLogoutRedirectUri: "https://portal.complaints.pgc.edu", 

    //  redirectUri: "http://complaintsportal.thetowertech.com",
    //  postLogoutRedirectUri: "http://complaintsportal.thetowertech.com",

     redirectUri: "http://localhost:4201",
     postLogoutRedirectUri: "http://localhost:4201",

      navigateToLoginRequestUrl: false,
      // storeAuthStateInCookie: true
    }),
    ToastrModule.forRoot(),
    AppRoutingModule,
    ErrorModule,

  ],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true
    },
    AuthGuard,
   
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
