 

export const environment = {
  production: false,
 baseUrl: 'https://mts.thetowertech.com',
//  baseUrl: 'http://10.12.4.82:5003',
//  baseUrl: 'http://mtsstaging.thetowertech.com',
//  baseUrl: 'http://localhost:5003',

   
  adalConfig: {
    tenant: '12b221b3-0464-4223-89e5-888835778b58',
    clientId: '9bf26259-22b5-4137-b900-56c27dcd0df4',
    redirectUri:"http://localhost:4100",
   // endpoints: {
    //  "https://localhost/Api/": "xxx-bae6-4760-b434-xxx"
   // },
    navigateToLoginRequestUrl: false,
    cacheLocation: 'localStorage',
  }
};

 