
var fireRefreshEventOnWindow = function () {
    var evt = document.createEvent("HTMLEvents");
    evt.initEvent('resize', true, false);
    window.dispatchEvent(evt);
};

export class SidebarHelper {

    public static windowResize() {
        let $sidebar = document.getElementsByClassName('app-sidebar');
        let $wrapper = document.getElementsByClassName('wrapper');
        if (window.innerWidth < 992) {
            $sidebar[0].classList.add('hide-sidebar');
            $wrapper[0].classList.remove('nav-collapsed');
            $wrapper[0].classList.remove('menu-collapsed')
        }
        if (window.innerWidth > 992) {
            $sidebar[0].classList.remove('hide-sidebar');
            let toggle_icon = document.getElementsByClassName('toggle-icon');
            let toggle = toggle_icon[0].getAttribute('data-toggle');
            let $wrapper = document.getElementsByClassName('wrapper');
            if (toggle === 'collapsed' && !$wrapper[0].classList.contains('nav-collapsed menu-collapsed')) {
                $wrapper[0].classList.add('nav-collapsed');
                $wrapper[0].classList.add('menu-collapsed');
            }
        }
    }

    public static initSideBar() {
        let $sidebar = document.getElementsByClassName('app-sidebar');
        let $sidebar_content = document.getElementsByClassName('sidebar-content');
        let $sidebar_img = $sidebar[0].getAttribute('data-image');
        let $sidebar_img_container = document.getElementsByClassName('sidebar-background') as HTMLCollectionOf<HTMLElement>;
        let $wrapper = document.getElementsByClassName('wrapper');

        if ($sidebar_img_container.length !== 0 && $sidebar_img !== undefined) {
            $sidebar_img_container[0].style.backgroundImage = 'url("' + $sidebar_img + '")';
        }

        setTimeout(() => {
            if (!$wrapper[0].classList.contains('nav-collapsed')) {
                const activeList = document.querySelectorAll('.sidebar-content li.active');
                if (activeList.length > 0) {
                    let parent = activeList[0].closest('ul').parentElement;
                    parent.classList.add('open');
                    parent.closest('ul').parentElement.classList.add('open');
                }
            }
        }, 50);
    }

    public static getTitle(state, parent) {
        var data = [];
        if (parent && parent.snapshot.data && parent.snapshot.data.title) {
            let page = {
                title: parent.snapshot.data.title,
                icon: parent.snapshot.data.icon
            }
            data.push(page);
        }
        if (state && parent) {
            data.push(... this.getTitle(state, state.firstChild(parent)));
        }
        return data;
    }

    public static navBarToggle($event) {
        //initialize window resizer event on sidebar toggle click event
        setTimeout(() => { fireRefreshEventOnWindow() }, 300);

        let toggle_icon = document.getElementsByClassName('toggle-icon');
        let toggle = toggle_icon[0].getAttribute('data-toggle');
        let $wrapper = document.getElementsByClassName('wrapper');
        if (toggle === 'expanded') {
            $wrapper[0].classList.add('nav-collapsed');
            toggle_icon[0].setAttribute('data-toggle', 'collapsed');
            toggle_icon[0].classList.add('ft-toggle-left');
            toggle_icon[0].classList.remove('ft-toggle-right');
        } else {
            $wrapper[0].classList.remove('nav-collapsed');
            $wrapper[0].classList.remove('menu-collapsed');

            toggle_icon[0].setAttribute('data-toggle', 'expanded');
            toggle_icon[0].classList.add('ft-toggle-right');
            toggle_icon[0].classList.remove('ft-toggle-left');
        }
    }

    public static handleChilds($event) {
        let listItem = $event.currentTarget.parentElement;
        if (listItem.classList.contains('has-sub') && listItem.classList.contains('open')) {
            this.collapse(listItem);
        }
        else {
            this.removeClassFromChildren(listItem.parentElement.children, 'open')
            if (listItem.classList.contains('has-sub')) {
                this.expand(listItem);
            }
        }
    }

    public static expand(listItem) {
        let subList = listItem.lastChild;
        let children = subList.children;
        this.addClassToChildren(children, 'is-hidden');
        listItem.classList.add('open');
        this.addClassToChildren(children, 'is-shown');
        this.removeClassFromChildren(children, 'is-hidden');
    }

    public static collapse(listItem) {
        let subList = listItem.lastChild;
        let children = subList.children;
        listItem.classList.remove('open');
        this.removeClassFromChildren(children, 'is-shown');
    }

    public static addClassToChildren(children, className) {
        for (let i = 0; i < children.length; i++) {
            children[i].classList.add(className);
        }
    }

    public static removeClassFromChildren(children, className) {
        for (let i = 0; i < children.length; i++) {
            children[i].classList.remove(className);
        }
    }

    public static onMouseEnterSidebar($event) {
        let $sidebar = document.getElementsByClassName('app-sidebar');
        let $wrapper = document.getElementsByClassName('wrapper');
        if ($wrapper[0].classList)
            if ($wrapper[0].classList.contains('nav-collapsed')) {
                $wrapper[0].classList.remove('menu-collapsed')

                let $listItem = document.querySelectorAll('.navigation li.nav-collapsed-open');
                let $subList = document.querySelectorAll('.navigation li.nav-collapsed-open ul');
                if ($listItem.length > 0) {
                    $listItem[0].classList.add('open');
                    $listItem[0].classList.remove('nav-collapsed-open');
                }
            }
    }

    public static onMouseLeaveSidebar($event) {
        let $sidebar = document.getElementsByClassName('app-sidebar');
        let $wrapper = document.getElementsByClassName('wrapper');
        if ($wrapper[0].classList)
            if ($wrapper[0].classList.contains('nav-collapsed')) {
                $wrapper[0].classList.add('menu-collapsed');

                let $listItem = document.querySelectorAll('.navigation li.open');
                let $subList = document.querySelectorAll('.navigation li.open ul');
                if ($listItem.length > 0) {
                    $listItem[0].classList.add('nav-collapsed-open');
                    $listItem[0].classList.remove('open');
                }
            }
    }
}