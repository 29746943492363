import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component'
import { BadRequestComponent } from './bad-request/bad-request.component';
const routes: Routes = [
  { path: 'authentication-required', component: NotAuthorizedComponent },
  { path: 'something-wrong', component: BadRequestComponent },
  { path: '**', component: NotFoundComponent }
 
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ErrorRoutingModule { }
