
export enum ComplaintStatusEnum {
    New = 0,
    InProcess = 1,
    Replied = 2,
    Resolved = 3
}

export enum ToasterPosition {
  topRight = 'toast-top-right',
  topLeft = 'toast-top-left',
  bottomRight = 'toast-bottom-right',
  bottomLeft = 'toast-bottom-left',
  // Other positions you would like
}

export enum DeviceTypeEnum {
  Desktop = 'Desktop',
  Tablet = 'Tablet',
  Mobile = 'Mobile'
}
