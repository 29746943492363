export var complaintData = [
    {
        complaintNumber: 'C112332',
        name: 'Zahra',
        phoneNumber: '03002863109',
        email: 'zahra@thetowertech.com',
        company: 'Punjab Group of Colleges',
        city: 'Lahore',
        campus: 'Campus 8',
        category: 'Others',
        subCategory: 'Others',
        status: 0,
        priority: 'High',
        assignTo: '-',
        issue: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages"
    },
    {
        complaintNumber: 'C112333',
        name: 'Fatima',
        phoneNumber: '03002863109',
        email: 'fatima@thetowertech.com',
        company: 'Punjab Group of Colleges',
        city: 'Lahore',
        campus: 'Campus 8',
        category: 'Others',
        subCategory: 'Others',
        status: 3,
        priority: 'High',
        assignTo: 'Farhan yousaf',
        issue: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages"
    },
    {
        complaintNumber: 'C112334',
        name: 'Bia',
        phoneNumber: '03002863109',
        email: 'bia@thetowertech.com',
        company: 'Punjab Group of Colleges',
        city: 'Lahore',
        campus: 'Campus 8',
        category: 'Others',
        subCategory: 'Others',
        status: 2,
        priority: 'Moderate',
        assignTo: 'Hajra Afzal',
        issue: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages"
   

    },
    {
        complaintNumber: 'C112335',
        name: 'Rahat',
        phoneNumber: '03002863109',
        email: 'rahat@thetowertech.com',
        company: 'Punjab Group of Colleges',
        city: 'Lahore',
        campus: 'Campus 8',
        category: 'Others',
        subCategory: 'Others',
        status: 3,
        priority: 'Low',
        assignTo: 'Hajra Afzal',
        issue: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages"
   

    },
    {
        complaintNumber: 'C112332',
        name: 'Zeenat',
        phoneNumber: '03002863109',
        email: 'zeenat@thetowertech.com',
        company: 'Punjab Group of Colleges',
        city: 'Lahore',
        campus: 'Campus 8',
        category: 'Others',
        subCategory: 'Others',
        status: 0,
        priority: 'High',
        assignTo: '-',
        issue: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages"
   

    },
    {
        complaintNumber: 'C112333',
        name: 'Zahra Fatima',
        phoneNumber: '03002863109',
        email: 'zahra.fatima@thetowertech.com',
        company: 'Punjab Group of Colleges',
        city: 'Lahore',
        campus: 'Campus 8',
        category: 'Others',
        subCategory: 'Others',
        status: 3,
        priority: 'High',
        assignTo: 'Farhan yousaf',
        issue: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages"
   

    },
    {
        complaintNumber: 'C112334',
        name: 'Zahra Fatima',
        phoneNumber: '03002863109',
        email: 'zahra.fatima@thetowertech.com',
        company: 'Punjab Group of Colleges',
        city: 'Lahore',
        campus: 'Campus 8',
        category: 'Others',
        subCategory: 'Others',
        status: 3,
        priority: 'Moderate',
        assignTo: 'Hajra Afzal',
        issue: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages"
   

    },
    {
        complaintNumber: 'C112335',
        name: 'Zahra Fatima',
        phoneNumber: '03002863109',
        email: 'zahra.fatima@thetowertech.com',
        company: 'Punjab Group of Colleges',
        city: 'Lahore',
        campus: 'Campus 8',
        category: 'Others',
        subCategory: 'Others',
        status: 2,
        priority: 'Low',
        assignTo: 'Hajra Afzal',
        issue: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages"
   

    },
    {
        complaintNumber: 'C112332',
        name: 'Zahra Fatima',
        phoneNumber: '03002863109',
        email: 'zahra.fatima@thetowertech.com',
        company: 'Punjab Group of Colleges',
        city: 'Lahore',
        campus: 'Campus 8',
        category: 'Others',
        subCategory: 'Others',
        status: 3,
        priority: 'High',
        assignTo: 'Hajra Afzal',
        issue: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages"
   

    },
    {
        complaintNumber: 'C112333',
        name: 'Zahra Fatima',
        phoneNumber: '03002863109',
        email: 'zahra.fatima@thetowertech.com',
        company: 'Punjab Group of Colleges',
        city: 'Lahore',
        campus: 'Campus 8',
        category: 'Others',
        subCategory: 'Others',
        status: 3,
        priority: 'High',
        assignTo: 'Farhan yousaf',
        issue: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages"
   

    },
    {
        complaintNumber: 'C112334',
        name: 'Zahra Fatima',
        phoneNumber: '03002863109',
        email: 'zahra.fatima@thetowertech.com',
        company: 'Punjab Group of Colleges',
        city: 'Lahore',
        campus: 'Campus 8',
        category: 'Others',
        subCategory: 'Others',
        status: 3,
        priority: 'Moderate',
        assignTo: 'Hajra Afzal',
        issue: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages"
   
    },
    {
        complaintNumber: 'C112335',
        name: 'Zahra Fatima',
        phoneNumber: '03002863109',
        email: 'zahra.fatima@thetowertech.com',
        company: 'Punjab Group of Colleges',
        city: 'Lahore',
        campus: 'Campus 8',
        category: 'Others',
        subCategory: 'Others',
        status: 2,
        priority: 'Low',
        assignTo: 'Hajra Afzal',
        issue: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages"
   

    },
    {
        complaintNumber: 'C112336',
        name: 'Mubashar Iqbal',
        phoneNumber: '03002863109',
        email: 'zahra.fatim1@thetowertech.com',
        company: 'EFA',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 3,
        priority: 'High',
        assignTo: 'Harris Bilal',
        issue: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages"
   
    },
    {
        complaintNumber: 'C112337',
        name: 'Mubashar Iqbal',
        phoneNumber: '03002863109',
        email: 'zahra1.fatim1@thetowertech.com',
        company: 'EFA',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 3,
        priority: 'Low',
        assignTo: 'Hajra Afzal',
        issue: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages"
    },
    {
        complaintNumber: 'C112337',
        name: 'Mubashar Iqbal',
        phoneNumber: '03002863109',
        email: 'zahra1.fatim1@thetowertech.com',
        company: 'Allied',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 3,
        priority: 'Low',
        assignTo: 'Hajra Afzal',
        issue: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages"
    },
    {
        complaintNumber: 'C112337',
        name: 'Mubashar Iqbal',
        phoneNumber: '03002863109',
        email: 'zahra1.fatim1@thetowertech.com',
        company: 'Allied',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 3,
        priority: 'Low',
        assignTo: 'Hajra Afzal',
        issue: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages"
    },
    {
        complaintNumber: 'C112337',
        name: 'Mubashar Iqbal',
        phoneNumber: '03002863109',
        email: 'zahra1.fatim1@thetowertech.com',
        company: 'Allied',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 3,
        priority: 'Low',
        assignTo: 'Hajra Afzal',
        issue: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages"
    },
    {
        complaintNumber: 'C112337',
        name: 'Mubashar Iqbal',
        phoneNumber: '03002863109',
        email: 'zahra1.fatim1@thetowertech.com',
        company: 'Allied',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 1,
        priority: 'Moderate',
        assignTo: 'Hajra Afzal',
        issue: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages"
    },
    {
        complaintNumber: 'C112337',
        name: 'Mubashar Iqbal',
        phoneNumber: '03002863109',
        email: 'zahra1.fatim1@thetowertech.com',
        company: 'Allied',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 2,
        priority: 'High',
        assignTo: 'Hajra Afzal',
        issue: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages"
    },
    {
        complaintNumber: 'C112338',
        name: 'Mubashar Iqbal',
        phoneNumber: '03002863109',
        email: 'MubasherIqbal@thetowertech.com',
        company: 'EFA',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 2,
        priority: 'High',
        assignTo: 'Harris Bilal',
        issue: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages"

    },
    {
        complaintNumber: 'C112339',
        name: 'Iqbal Mubashar',
        phoneNumber: '03002863109',
        email: 'Mubasher1Iqbal@thetowertech.com',
        company: 'EFA',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 0,
        priority: 'Moderate',
        assignTo: '-',
        issue: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages"
        
    },
    {
        complaintNumber: 'C112340',
        name: 'Mubashar Iqbal',
        phoneNumber: '03002863109',
        email: 'Mubasher1Iqbal@thetowertech.com',
        company: 'EFA',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 3,
        priority: 'High',
        assignTo: 'Hajra Afzal',
        issue: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages"
        
    },
    {
        complaintNumber: 'C112341',
        name: 'Mubashar1  Iqbal',
        phoneNumber: '03002863109',
        email: 'Mubasher1Iqbal1@thetowertech.com',
        company: 'EFA',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 3,
        priority: 'High',
        assignTo: 'Hajra Afzal',
        issue: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages"
        
    },
    {
        complaintNumber: 'C112342',
        name: 'Mubashar1  Iqbal2',
        phoneNumber: '03002863109',
        email: 'Mubasher1Iqbal2@thetowertech.com',
        company: 'EFA',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 2,
        priority: 'Low',
        assignTo: 'Farhan Yousaf',
        issue: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages"
        
    },
    {
        complaintNumber: 'C112343',
        name: 'Shazaib  Iqbal2',
        phoneNumber: '03002863109',
        email: 'shazaibqbal2@thetowertech.com',
        company: 'EFA',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 0,
        priority: 'High',
        assignTo: '-',
        issue: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages"
        
    },
    {
        complaintNumber: 'C112344',
        name: 'Shazaib  Iqbal',
        phoneNumber: '03002863109',
        email: 'shazaibiqbal45@thetowertech.com',
        company: 'EFA',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 0,
        priority: 'Moderate',
        assignTo: '-',
        issue: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages"
        
    },
    {
        complaintNumber: 'C112345',
        name: 'Shazaib12  Iqbal',
        phoneNumber: '03002863109',
        email: 's12hazaibiqbal45@efa.edu.pk',
        company: 'EFA',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 0,
        priority: 'High',
        assignTo: '-'
    },
    {
        complaintNumber: 'C112346',
        name: 'Shazaib12  Iqbal12',
        phoneNumber: '03002863109',
        email: 's12hazai12biqbal45@efa.edu.pk',
        company: 'EFA',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 0,
        priority: 'High',
        assignTo: '-'
    },
    {
        complaintNumber: 'C112347',
        name: 'Shazaib12  Iqbal12',
        phoneNumber: '03002863109',
        email: 's12hazabi12biqbal45@efa.edu.pk',
        company: 'EFA',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 0,
        priority: 'Moderate',
        assignTo: '-'
    },
    {
        complaintNumber: 'C112348',
        name: 'Mudasar  Hussain',
        phoneNumber: '03002863109',
        email: 'MudasarHussain@efa.edu.pk',
        company: 'EFA',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 0,
        priority: 'High',
        assignTo: '-'
    },
    {
        complaintNumber: 'C112349',
        name: 'Mudasar  Hussain1',
        phoneNumber: '03002863109',
        email: 'Mudasar.Hussain@efa.edu.pk',
        company: 'EFA',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 0,
        priority: 'High',
        assignTo: '-'
    },
    {
        complaintNumber: 'C112350',
        name: 'Mudasar1  Hussain1',
        phoneNumber: '03002863109',
        email: 'Mudasar1.Hussaain@efa.edu.pk',
        company: 'EFA',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 0,
        priority: 'Low',
        assignTo: '-'
    },
    {
        complaintNumber: 'C112351',
        name: 'Mudaasar1  Hussaian1',
        phoneNumber: '03002863109',
        email: 'Mudasar1.Hussain@efa.edu.pk',
        company: 'EFA',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 0,
        priority: 'Moderate',
        assignTo: '-'
    },
    {
        complaintNumber: 'C112352',
        name: 'Mudasar1  Hussain13',
        phoneNumber: '03002863109',
        email: 'Mudasar1.Hussain1@efa.edu.pk',
        company: 'EFA',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 0,
        priority: 'High',
        assignTo: '-'
    },
    {
        complaintNumber: 'C112353',
        name: 'Mudasar13  Hussain1',
        phoneNumber: '03002863109',
        email: 'Mudasar1.Hussain1@efa.edu.pk',
        company: 'EFA',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 0,
        priority: 'Low',
        assignTo: '-'
    },
    {
        complaintNumber: 'C112354',
        name: 'Mudasar1  Hussain31',
        phoneNumber: '03002863109',
        email: 'Mudasar1.Hussaaain1@cust.edu.pk',
        company: 'CUST',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 0,
        priority: 'High',
        assignTo: '-'
    },
    {
        complaintNumber: 'C112355',
        name: 'Mudasar31  Hussain1',
        phoneNumber: '03002863109',
        email: 'Mudasar12.Hussaaain1@cust.edu.pk',
        company: 'CUST',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 0,
        priority: 'High',
        assignTo: '-'
    },
    {
        complaintNumber: 'C112356',
        name: 'Mudasar21  Hussain21',
        phoneNumber: '03002863109',
        email: 'Mudasar12.Hussaaain11@cust.edu.pk',
        company: 'CUST',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 0,
        priority: 'Moderate',
        assignTo: '-'
    },
    {
        complaintNumber: 'C112357',
        name: 'Mudasar13  Hussain13',
        phoneNumber: '03002863109',
        email: 'Mudasar12.Hussaaain121@cust.edu.pk',
        company: 'CUST',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 0,
        priority: 'High',
        assignTo: '-'
    },
    {
        complaintNumber: 'C112357',
        name: 'Mudasar12  Hussain12',
        phoneNumber: '03002863109',
        email: 'Mudasar12.Hussaaain121@maju.edu.pk',
        company: 'MAJU',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 0,
        priority: 'Moderate',
        assignTo: '-'
    },
    {
        complaintNumber: 'C112358',
        name: 'Mehmood riaz',
        phoneNumber: '03002863109',
        email: 'Mehmood.riaz@maju.edu.pk',
        company: 'MAJU',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 0,
        priority: 'High',
        assignTo: '-'
    },
    {
        complaintNumber: 'C112359',
        name: 'Mehmood riaz1',
        phoneNumber: '03002863109',
        email: 'Mehmood1.riaz@maju.edu.pk',
        company: 'MAJU',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 0,
        priority: 'High',
        assignTo: '-'
    },
    {
        complaintNumber: 'C112360',
        name: 'Mehmood riaz1',
        phoneNumber: '03002863109',
        email: 'Mehmood1.riaz1@maju.edu.pk',
        company: 'MAJU',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 0,
        priority: 'Low',
        assignTo: '-'
    },
    {
        complaintNumber: 'C112361',
        name: 'Ahsan iqbal',
        phoneNumber: '03002863109',
        email: 'Ahsan.iqbal@maju.edu.pk',
        company: 'MAJU',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 0,
        priority: 'High',
        assignTo: '-'
    },
    {
        complaintNumber: 'C112362',
        name: 'mujahid Ahsan ',
        phoneNumber: '03002863109',
        email: 'mujhai.iqbal@maju.edu.pk',
        company: 'MAJU',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 0,
        priority: 'High',
        assignTo: '-'
    },
    {
        complaintNumber: 'C112363',
        name: 'mujahid ahmad ',
        phoneNumber: '03002863109',
        email: 'ahmadmujahid.iqbal@maju.edu.pk',
        company: 'MAJU',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 0,
        priority: 'Moderate',
        assignTo: '-'
    },
    {
        complaintNumber: 'C112364',
        name: 'mujahid ahmad ',
        phoneNumber: '03002863109',
        email: 'ahmadmujahid1.iqbal@maju.edu.pk',
        company: 'MAJU',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 0,
        priority: 'High',
        assignTo: '-'
    },
    {
        complaintNumber: 'C112365',
        name: 'Hina sheraz ',
        phoneNumber: '03002863109',
        email: 'Hina.sheraz@maju.edu.pk',
        company: 'MAJU',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 0,
        priority: 'High',
        assignTo: '-'
    },
    {
        complaintNumber: 'C112366',
        name: 'Hina sheraz1 ',
        phoneNumber: '03002863109',
        email: 'Hina.sheraz1@maju.edu.pk',
        company: 'MAJU',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 0,
        priority: 'Low',
        assignTo: '-'
    },
    {
        complaintNumber: 'C112367',
        name: 'Sheza sheraz ',
        phoneNumber: '03002863109',
        email: 'Sheza.sheraz1@ra.edu.pk',
        company: 'RA',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 0,
        priority: 'High',
        assignTo: '-'
    },
    {
        complaintNumber: 'C112368',
        name: 'Sheza sheraz1 ',
        phoneNumber: '03002863109',
        email: 'Sheza1.sheraz1@ra.edu.pk',
        company: 'RA',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 0,
        priority: 'Moderate',
        assignTo: '-'
    },
    {
        complaintNumber: 'C112369',
        name: 'Sheza sheraz1 ',
        phoneNumber: '03002863109',
        email: 'Sheza1.sheraz1@ra.edu.pk',
        company: 'RA',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 0,
        priority: 'High',
        assignTo: '-'
    },
    {
        complaintNumber: 'C112370',
        name: 'sana shaheen ',
        phoneNumber: '03002863109',
        email: 'sana.shaheen@ra.edu.pk',
        company: 'RA',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 0,
        priority: 'Moderate',
        assignTo: '-'
    },
    {
        complaintNumber: 'C112371',
        name: 'sana shaheen ',
        phoneNumber: '03002863109',
        email: 'sana1.shaheen@adp.edu.pk',
        company: 'ADP',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 0,
        priority: 'High',
        assignTo: '-'
    },
    {
        complaintNumber: 'C112372',
        name: 'shaheen usman ',
        phoneNumber: '03002863109',
        email: 'shaheen.usman@adp.edu.pk',
        company: 'ADP',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 0,
        priority: 'Low',
        assignTo: '-'
    },
    {
        complaintNumber: 'C112373',
        name: 'shaheen1 shaheen ',
        phoneNumber: '03002863109',
        email: 'shaheen12.shaheen@adp.edu.pk',
        company: 'ADP',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 0,
        priority: 'High',
        assignTo: '-'
    },
    {
        complaintNumber: 'C112374',
        name: 'sana shaheen12 ',
        phoneNumber: '03002863109',
        email: 'sana1.shahee11n@adp.edu.pk',
        company: 'ADP',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 0,
        priority: 'High',
        assignTo: '-'
    },

    {
        complaintNumber: 'C112375',
        name: 'shaheen amir ',
        phoneNumber: '03002863109',
        email: 'shaheen.amir@ucp.edu.pk',
        company: 'UCP',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 0,
        priority: 'Low',
        assignTo: '-'
    },
    {
        complaintNumber: 'C112376',
        name: 'shaheen amir ',
        phoneNumber: '03002863109',
        email: 'shaheen.amir@ucp.edu.pk',
        company: 'UCP',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 0,
        priority: 'Low',
        assignTo: '-'
    },
    {
        complaintNumber: 'C112377',
        name: 'Amir butt ',
        phoneNumber: '03002863109',
        email: 'Amir.amir@ucp.edu.pk',
        company: 'UCP',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 0,
        priority: 'Low',
        assignTo: '-'
    },
    {
        complaintNumber: 'C112378',
        name: 'Amir amir ',
        phoneNumber: '03002863109',
        email: 'Amir1.amir@ucp.edu.pk',
        company: 'UCP',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 0,
        priority: 'Low',
        assignTo: '-'
    },
    {
        complaintNumber: 'C112379',
        name: 'Nasir amir ',
        phoneNumber: '03002863109',
        email: 'Nasir.amir@ucp.edu.pk',
        company: 'UCP',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 0,
        priority: 'Low',
        assignTo: '-'
    },
    {
        complaintNumber: 'C112380',
        name: 'Nasir1 amir ',
        phoneNumber: '03002863109',
        email: 'shaheen.amir@ucp.edu.pk',
        company: 'UCP',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 0,
        priority: 'Low',
        assignTo: '-'
    },
    {
        complaintNumber: 'C112381',
        name: 'shaheen amir ',
        phoneNumber: '03002863109',
        email: 'shaheen.amir@ucp.edu.pk',
        company: 'UCP',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 0,
        priority: 'Low',
        assignTo: '-'
    },
    {
        complaintNumber: 'C112382',
        name: 'shaheen amir ',
        phoneNumber: '03002863109',
        email: 'shaheen.amir@ucp.edu.pk',
        company: 'UCP',
        city: 'Lahore',
        campus: 'Campus 7',
        category: 'Others',
        subCategory: 'Others',
        status: 0,
        priority: 'Low',
        assignTo: '-'
    },
]