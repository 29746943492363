import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, CanLoad, Route } from "@angular/router";
import { Observable } from "rxjs";
import { TokenHelper } from '../common';
import { AuthService } from '../_services/auth.service';


@Injectable()
export class AuthGuard implements CanActivate, CanLoad {
  constructor(private router: Router, private authService: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    window.scrollTo(0, 0);
    let token = TokenHelper.getAccessToken();
    if (token && !TokenHelper.isTokenCurrent(token)) {
      this.authService.signOut(false).subscribe(result=>{

      });
      return false;
    }
    else if (!token) {
      this.router.navigate(['/']);
      return false
    }
    else if (token && TokenHelper.isTokenCurrent(token) && localStorage.getItem('userStatus')==undefined) {
     this.authService.signOut(false).subscribe(result=>{

      })
      return false;
    }
    else
      return true;
  }

  canLoad(route: Route): boolean {
    window.scrollTo(0, 0);
    if (!localStorage.getItem('')) {
      this.router.navigate(['/']);
      return true;
    }
    else {
      this.router.navigate([route.path ? route.path : '/dashboard']);
      return false;
    }
  }
}
