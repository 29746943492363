import {
    trigger,
    animate,
    transition,
    style,
    query,
    state, animateChild, group
} from '@angular/animations';

export class RouterAnimations {
    static routeSlide =
        trigger('routeSlide', [
            transition('* <=> *', [
                group([
                    query(':enter', [
                        style({ transform: 'translateY(0%)' }),
                        animate('0.4s ease-in-out', style({ transform: 'translateY(0%)' }))
                    ], { optional: true }),
                    query(':leave', [
                        style({ transform: 'translateY(0%)' }),
                        animate('0.4s ease-in-out', style({ transform: 'translateY(100%)' }))
                    ], { optional: true }),
                ])
            ]),
        ]);
}

export const openClose = trigger('openClose', [
    state('open', style({
        height: '*',
        opacity: '1'
    })),
    state('closed', style({
        height: '0px',
        opacity: '0'
    })),
    transition('open => closed', [
        animate('0.3s')
    ]),
    transition('closed => open', [
        animate('0.3s')
    ]),
])
export const fadeAnimation = trigger('fadeAnimation', [
    transition('* => *', [
        query(
            ':enter',
            [style({ opacity: 0 })],
            { optional: true }
        ),
        query(
            ':leave',
            [style({ opacity: 1 }), animate('0.3s', style({ opacity: 0 }))],
            { optional: true }
        ),
        query(
            ':enter',
            [style({ opacity: 0 }), animate('0.3s', style({ opacity: 1 }))],
            { optional: true }
        )
    ])
]);

export const slideInOutAnimation =
    trigger('slideInOutAnimation', [

        state('*', style({
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.8)'
        })),

        transition(':enter', [
            style({
                right: '-400%',
                backgroundColor: 'rgba(0, 0, 0, 0)'
            }),
            animate('.5s ease-in-out', style({
                right: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.8)'
            }))
        ]),

        transition(':leave', [
            animate('.5s ease-in-out', style({
                right: '-400%',
                backgroundColor: 'rgba(0, 0, 0, 0)'
            }))
        ])
    ]);

export const animateRoute =
    trigger('animRoutes', [
        transition('* <=> *', [
            group([
                query(
                    ':enter',
                    [
                        style({
                            opacity: 0,
                            transform: 'translateY(9rem) rotate(-10deg)'
                        }),
                        animate(
                            '0.35s cubic-bezier(0, 1.8, 1, 1.8)',
                            style({ opacity: 1, transform: 'translateY(0) rotate(0)' })
                        ),
                        animateChild()
                    ],
                    { optional: true }
                ),
                query(
                    ':leave',
                    [animate('0.35s', style({ opacity: 0 })), animateChild()],
                    { optional: true }
                )
            ])
        ])
    ])


export const slideLeft = [
    query(':leave', style({ position: 'absolute', left: 0, right: 0, transform: 'translate3d(0%,0,0)' }), { optional: true }),
    query(':enter', style({ position: 'absolute', left: 0, right: 0, transform: 'translate3d(-100%,0,0)' }), { optional: true }),
    group([
        query(':leave', group([
            animate('500ms cubic-bezier(.35,0,.25,1)', style({ transform: 'translate3d(100%,0,0)' })), // y: '-100%'
        ]), { optional: true }),
        query(':enter', group([
            animate('500ms cubic-bezier(.35,0,.25,1)', style({ transform: 'translate3d(0%,0,0)' })),
        ]), { optional: true })
    ])
]

export const slideRight = [
    query(':leave', style({ position: 'absolute', left: 0, right: 0, transform: 'translate3d(0%,0,0)' }), { optional: true }),
    query(':enter', style({ position: 'absolute', left: 0, right: 0, transform: 'translate3d(100%,0,0)' }), { optional: true }),

    group([
        query(':leave', group([
            animate('500ms cubic-bezier(.35,0,.25,1)', style({ transform: 'translate3d(-100%,0,0)' })), // y: '-100%'
        ]), { optional: true }),
        query(':enter', group([
            animate('500ms cubic-bezier(.35,0,.25,1)', style({ transform: 'translate3d(0%,0,0)' })),
        ]), { optional: true })
    ])
]