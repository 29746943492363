import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import { pipe } from '@angular/core/src/render3';
import { finalize } from 'rxjs/operators';
import { Alerts, ResultMessages, TokenHelper } from '../common';
import { AuditTrailService } from '../_services/audit-trail.service';
import { AuditTrail } from '../interfaces';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  loading: boolean;
  constructor(private router: Router, private authService: AuthService, private auditService: AuditTrailService) { }

  ngOnInit() {
 
      this.router.navigate(['/dashboard'])
  
  }

 
}
